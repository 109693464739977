// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_IMAGE_API: API_BASE_IMAGE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;


// LOCALSTORAGE KEYNAME
export const LS_USER = `user${APP_NAME}`;
export const LS_AUTHTOKEN = `authToken${APP_NAME}`;



// TYPES FOR REDUCER
export const API = 'API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'



export const LOGIN_S = `LOGIN_S`;
export const LOGIN_F = `LOGIN_F`;

export const UPDATE_USER_POINTS = `UPDATE_USER_POINTS`;

export const USER_LIST_S = `USER_LIST_S`;
export const USER_LIST_F = `USER_LIST_F`;

export const USER_INFO_S = `USER_INFO_S`;
export const USER_INFO_F = `USER_INFO_F`;

export const CART_S = `CART_S`;
export const CART_F = `CART_F`;

export const USER_POINT_S = `USER_POINT_S`;
export const USER_POINT_F = `USER_POINT_F`;

export const CART_ITEMS_COUNT_S = `CART_ITEMS_COUNT_S`;
export const CART_ITEMS_COUNT_F = `CART_ITEMS_COUNT_F`;


//api

export const API_LOGIN = `${API_BASE}web/users/login`;
export const API_VIEW = "/restaurant/find";

//api products

export const API_GET_PRODUCTS = `${API_BASE}web/product/list`;


//api products
export const API_ADD_TO_CART = `${API_BASE}web/cart/create`;
export const API_GET_CART_ITEMS = `${API_BASE}web/cart/get/`;
export const API_GET_USER_POINTS = `${API_BASE}web/users/getPoints/`;
export const API_CART_ITEMS_COUNT = `${API_BASE}web/cart/count/`;
export const API_MINUS_PLUS_CART = `${API_BASE}web/cart/minusPlusCart`;
export const API_CONFIRM_ORDER = `${API_BASE}web/order/confirm`;

//api image
export const API_DEFAULT_IMAGE = `${API_BASE_IMAGE}/defaultImage/32690428.jpg`;
export const API_PRODUCT_IMAGE = `${API_BASE_IMAGE}/product/`;
