import { createSlice } from "@reduxjs/toolkit";
import { API_ADD_TO_CART, API_CART_ITEMS_COUNT, API_CONFIRM_ORDER, API_GET_CART_ITEMS, API_GET_USER_POINTS, API_MINUS_PLUS_CART, CART_F, CART_ITEMS_COUNT_F, CART_ITEMS_COUNT_S, CART_S, USER_POINT_F, USER_POINT_S } from "../constants";

const initialState = {
  // Global loader for api
  isLoading: false,

  cartData: [],
  totalPoints: 0,
  totalUserPoints: 0,
  totalCartItems: 0,
  cartId: "",
};

export const addItemToCart = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_TO_CART,
    method: "POST",
    data: data,
    hideLoader: false,
    // success: (data) => ({
    //   type: CART_S,
    //   payload: data,
    // }),
    // error: (data) => ({
    //   type: CART_F,
    //   payload: {},
    // }),
  },
});

export const getUserPoints = (data) => ({
  type: "API",
  payload: {
    url: API_GET_USER_POINTS + data,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: USER_POINT_S,
      payload: data,
    }),
    error: (data) => ({
      type: USER_POINT_F,
      payload: {},
    }),
  },
});

export const getCartItems = (data) => ({
  type: "API",
  payload: {
    url: API_GET_CART_ITEMS + data,
    method: "GET",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: CART_S,
      payload: data,
    }),
    error: (data) => ({
      type: CART_F,
      payload: {},
    }),
  },
});

export const cartItemCount = (data) => ({
  type: "API",
  payload: {
    url: API_CART_ITEMS_COUNT + data,
    method: "GET",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: CART_ITEMS_COUNT_S,
      payload: data,
    }),
    error: (data) => ({
      type: CART_ITEMS_COUNT_F,
      payload: {},
    }),
  },
});

export const minusPlusCart = (data) => ({
  type: "API",
  payload: {
    url: API_MINUS_PLUS_CART,
    method: "POST",
    data: data,
    hideLoader: false,
    // success: (data) => ({
    //   type: CART_S,
    //   payload: data,
    // }),
    // error: (data) => ({
    //   type: CART_F,
    //   payload: {},
    // }),
  },
});

export const orderConfirmation = (data) => ({
  type: "API",
  payload: {
    url: API_CONFIRM_ORDER,
    method: "POST",
    data: data,
    hideLoader: false,
    // success: (data) => ({
    //   type: CART_S,
    //   payload: data,
    // }),
    // error: (data) => ({
    //   type: CART_F,
    //   payload: {},
    // }),
  },
});

// Reducer
const CartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    loaderChange: (state, payload) => {
      state.isLoading = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CART_S, (state, action) => {
      state.cartData = action?.payload?.data?.cartData;
      state.totalPoints = action?.payload?.data?.totalPoints;
      state.cartId = action?.payload?.data?._id;

    });

    builder.addCase(CART_ITEMS_COUNT_S, (state, action) => {
      state.totalCartItems = action?.payload?.data?.cartCount;
    });

    builder.addCase(USER_POINT_S, (state, action) => {
      state.totalUserPoints = action?.payload?.data?.userPoints;

    });

  },
});

export const { loaderChange } = CartSlice.actions;
export default CartSlice.reducer;
