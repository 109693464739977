import { configureStore } from '@reduxjs/toolkit'
import reduxApiMiddleware from './Middleware'
import thunk from 'redux-thunk'
import AuthSlice from './AuthSlice'
import ProductSlice from './ProductSlice'
import CartSlice from './CartSlice'

export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        product: ProductSlice,
        cart: CartSlice,
    },
    middleware: [thunk, reduxApiMiddleware]
}) 