import { LOGIN_F, LOGIN_S, LS_AUTHTOKEN, LS_USER } from "../constants";

//To concate the path for the public folder
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

export const setupAxios = (axios, store) => {

    let token = JSON.parse(localStorage.getItem(LS_AUTHTOKEN))
    let userData = JSON.parse(localStorage.getItem(LS_USER))

    if (token) {
        store.dispatch({ type: LOGIN_S, payload: userData })
    } else {
        store.dispatch({ type: LOGIN_F, payload: {} })
    }

    axios.interceptors.request.use(req => {
        const { auth: { userData } } = store.getState();

        if (userData && userData.authToken) {
            // axios.defaults.headers.common['Authorization'] = `Bearer ${userData.authToken}`;
            req.headers["Authorization"] = `Bearer ${userData.authToken}`;
        }

        return req;
    }, err => Promise.reject(err));

    axios.interceptors.response.use(null, (err) => {
        if (err.response) {
            if (err.response.status === 403) {
                store.dispatch({ type: LOGIN_F, payload: {} });
                return Promise.reject(err);
            } else return Promise.reject(err);
        } else if (err.request) {
            return Promise.reject({
                response: {
                    data: {
                        message: "Something went wrong, Please try again later!!!"
                    }
                }
            });
        }
    });
}
