import { createSlice } from "@reduxjs/toolkit";
import { API_GET_PRODUCTS } from "../constants";

const initialState = {
  // Global loader for api
  isLoading: false,

  products: [],
};

export const fetchProducts = (data) => ({
  type: "API",
  payload: {
    url: API_GET_PRODUCTS,
    method: "POST",
    data: data,
    hideLoader: false,
    // success: (data) => ({
    //   type: LOGIN_S,
    //   payload: data,
    // }),
    // error: (data) => ({
    //   type: LOGIN_F,
    //   payload: {},
    // }),
  },
});

// Reducer
const ProductSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loaderChange: (state, payload) => {
      state.isLoading = payload.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(LOGIN_S, (state, action) => {
      
    // });
    // builder.addCase(LOGIN_F, (state, action) => {
      
    // });
  },
});

export const { loaderChange } = ProductSlice.actions;
export default ProductSlice.reducer;
